import * as React from 'react'
import { Translation } from '../../types/main'
import { translations } from '../../translations'
import AboutContainer from '../../containers/about'
import Seo from '../../components/seo'

const t: Translation = {
  language: 'se',
  translations: translations.se
}

const AboutPage = () => (
  <>
    <Seo title="Om oss" lang="sv" />
    <AboutContainer t={t} />
  </>
)

export default AboutPage
